const listVersions = {
    "haccp": {
        code: "HACCP",
        label: "HACCP",
        fileNamePrefix: "netresto-haccp-",
        fileNameSuffix: "-prod-release.apk",
        path: "haccp",
        supporteOs: ["android"],
        list: [
            // version item
            // id: version code of the app
            // label: version name of the app
            // date : publish date
            // type : release type
            // latest : can be true to set the latest version (must be unique in the list)
            // visible : can be false to not display the version is the download list
            {id: "1770328", label:"1.77.03.29", date: "2024-04-17", type: "PATCH", latest: true}
        //     {id: "1770328", label:"1.77.03.28", date: "2024-02-26", type: "PATCH"},
        //     {id: "1770327", label:"1.77.03.27", date: "2023-12-20", type: "PATCH"},
        //     {id: "1770326", label:"1.77.03.26", date: "2023-09-20", type: "PATCH"},
        //     {id: "1770325", label:"1.77.03.25", date: "2023-06-27", type: "PATCH"},
        //     {id: "1770321", label:"1.77.03.21", date: "2023-06-05", type: "PATCH"},
        //     {id: "1769210", label:"1.76.92.10", date: "2022-01-07", type: "RC"}
        ]
    },
    "haccp2023": {
        "hideOnHome": true,
        code: "HACCP2023",
        label: "HACCP",
        fileNamePrefix: "netresto-haccp-",
        fileNameSuffix: "-prod-release.apk",
        path: "haccp2023",
        supporteOs: ["android"],
        list: [
            {id: "1780323", label:"1.78.03.23", date: "2025-08-06", type: "PATCH", latest: true}
        ]
    },
    "apps": {
        enable: false,
        code: "APPS",
        label: "APPS",
        fileNamePrefix: "netresto-apps-",
        fileNameSuffix: "-prod-release.apk",
        path: "apps",
        supporteOs: ["android"],
        list: [
            {label:"2.19.0", build:"219", date: "2024-10-21", type: "MINOR",latest: true},
            // {label:"2.18", date: "2024-02-20", type: "PATCH"},
            // {label:"2.17", date: "2024-02-05", type: "PATCH"}
            // {label:"2.16", date: "2023-09-20", type: "PATCH"},
            // {label:"2.15", date: "2023-09-01", type: "PATCH"},
            // {label:"2.14", date: "2023-06-21", type: "PATCH"},
            // {label:"2.13", date: "2023-06-05", type: "PATCH"},
            // {label:"2.12", date: "2023-04-26", type: "PATCH"},
            // {label:"2.10", date: "2023-01-17", type: "STABLE"},
            // {label:"2.09", date: "2023-01-17", type: "STABLE"},
            // {label:"2.08", date: "2023-01-04", type: "STABLE"}
        ]
    },
    "pointeuse": {
        code: "POINTEUSE",
        label: "Pointeuse",
        fileNamePrefix: "netresto-pointeuse-",
        fileNameSuffix: "-prod-release.apk",
        path: "pointeuse",
        supporteOs: ["android"],
        list: [
            {label:"2.64", date: "2024-04-30", type: "PATCH", latest: true},
            // {label:"2.63", date: "2024-03-07", type: "PATCH"}
        //     {label:"2.62", date: "2024-03-06", type: "PATCH"},
        //     {label:"2.61", date: "2024-02-23", type: "PATCH"},
        //     {label:"2.60", date: "2024-02-20", type: "PATCH"},
        //     {label:"2.59", date: "2023-11-16", type: "MINOR"},
        //     {label:"2.58", date: "2023-04-28", type: "RC"},
        //     {label:"2.57", date: "2023-02-20", type: "RC"},
        //     {label:"2.56", date: "2022-09-20", type: "RC"}
        ]
    },
    "netrestomobile": {
        code: "MOBILE",
        // le fichier pour netresto mobile est renommé pour avoir la meme pattern que les autres application
        label: "Netresto Mobile",
        fileNamePrefix: "netresto-mobile-",
        fileNameSuffix: "-prod.apk",
        path: "netrestomobile",
        supporteOs: ["android", "ios"],
        list: [
            {label:"2.6.4", date: "2020-06-12"},
            
        ]
    },
    "haccpbeta": {
        // isbeta value able to hide this app from the homepage
        label: "HACCP Beta",
        fileNamePrefix: "netresto-haccp-",
        fileNameSuffix: "-prodbeta-release.apk",
        path: "haccpbeta",
        supporteOs: ["android"],
        isbeta: true,
        list: [
            {label:"1.76.87.08", date: "2020-11-23", type: "B"},
            {label:"1.76.86.05", date: "2020-11-23", type: "B"}
        ]
    }
};

const listTesting = [
    {appName: "haccp", server: "preprod", version: "version sirha 2025", filename: "mycawan-haccp-sirha-preprod-release.apk"},
    // {appName: "haccp", server: "preprod", version: "master hotfix", filename: "mycawan-haccp-master-hotfix-preprod-release.apk"},
    // {appName: "haccp", server: "preprod", version: "v2.7-testing", filename: "mycawan-haccp-testing-preprod-release.apk"},
    {appName: "apps", server: "preprod", version: "3.1.0-testing", filename: "netresto-apps-preprod-release.apk"},
    {appName: "pointeuse", server: "preprod", version: "2.60", filename: "netresto-pointeuse-preprod-release.apk"}
];


export { listVersions, listTesting };